import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'orders',
        component: () => import('../views/OrdersView.vue')
    },
    {
        path: '/services',
        name: 'services',
        component: () => import('../views/ServicesView.vue')
    },
    {
        path: '/categories',
        name: 'categories',
        component: () => import('../views/CategoriesView.vue')
    },
    {
        path: '/seo',
        name: 'seo',
        component: () => import('../views/SeoView.vue')
    },
    {
        path: '/new',
        name: 'newCourse',
        component: () => import('../views/NewServiceView.vue')
    },
    {
        path: '/services/:id',
        name: 'service',
        component: () => import('../views/ServiceView.vue')
    },
    {
        path: '/pages/contacts',
        name: 'contacts',
        component: () => import('../views/PageContactsView.vue')
    },
    {
        path: '/pages/general',
        name: 'general',
        component: () => import('../views/PageGeneralInfoView.vue')
    },
    {
        path: '/pages/home',
        name: 'homepage',
        component: () => import('../views/PageHomeView.vue')
    },
    {
        path: '/pages/services',
        name: 'servicespage',
        component: () => import('../views/PageServicesView.vue')
    },
    {
        path: '/pages/credits',
        name: 'credits',
        component: () => import('../views/PageCreditsView.vue')
    },
    {
        path: '/pages/about',
        name: 'about',
        component: () => import('../views/PageAboutView.vue')
    },
    {
        path: '/pages/team',
        name: 'team',
        component: () => import('../views/PageTeamView.vue')
    },
    {
        path: '/pages/sales',
        name: 'sales',
        component: () => import('../views/PageSalesView.vue')
    },
    {
        path: '/pages/reviews',
        name: 'reviews',
        component: () => import('../views/PageReviewsView.vue')
    }
]

const router = new VueRouter({
//   mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return {x: 0, y: 0};
	}
})

export default router
